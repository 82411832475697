import { Button, Checkbox, Form, Radio, Select, Spin } from "antd";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import QuestionTitle from "./components/QuestionTitle";
import Stepper from "./components/Stepper";
import TradeInResult from "./components/TradeInResult";
import { useTradeIn } from "./hooks/useTradeIn";
import useTradeInStore from "./hooks/useTradeInStore";
import { services } from "./libs/service";
export type ProductDiagnosticFormState = Record<string, string>;
export function ProductDiagnosticForm() {
  const {
    i18n,
    t
  } = useTranslation();
  const {
    formValues,
    selectedVariant,
    setDiagnosticQuoted,
    setFormValues,
    setStep,
    step
  } = useTradeInStore();
  const [form] = Form.useForm<ProductDiagnosticFormState>();
  const {
    data: questions,
    isLoading
  } = useTradeIn().getDiagnosticQuestions(formValues.selectedDevice?.variant!);
  const {
    getDiagnosticQuotedPrice
  } = services;
  const handleOnSubmit = useCallback(async (values: ProductDiagnosticFormState) => {
    const response = await getDiagnosticQuotedPrice({
      diagnosticResult: values,
      productVariantId: selectedVariant?.productVariantId.toString()!
    });
    if (response) {
      setDiagnosticQuoted(response.data);
    }
    setFormValues({
      ...formValues,
      productDiagnostic: values
    });
    setStep(3);
  }, [getDiagnosticQuotedPrice, selectedVariant?.productVariantId, setFormValues, formValues, setStep, setDiagnosticQuoted]);
  const deviceIssues = Form.useWatch("DEIS-01", form);
  useEffect(() => {
    if (deviceIssues) {
      // check if it includes deis_none_above then remove all other values
      if (deviceIssues.includes("deis_none_above")) {
        form.setFieldsValue({
          // @ts-expect-error This field name is dynamic based on the question
          "DEIS-01": ["deis_none_above"]
        });
      } else {
        form.setFieldsValue({
          "DEIS-01": deviceIssues
        });
      }
    }
  }, [deviceIssues, form]);
  const translateText = (questionCode: string, questionText: string) => {
    if (i18n.language === "en") {
      return questionText;
    }
    const translations: {
      [key: string]: string;
    } = {
      camera_both: "กล้องหน้าและกล้องหลังทำงานปกติใช่หรือไม่?",
      cracked: "รอยแตก",
      deis_bloated_battery: "แบตบวม แบตเตอรี่ / หน้าจอหลุดออกมา",
      deis_device_cannot_on_data_erased: "เครื่องไม่สามารถเปิด หรือล้างข้อมูลได้",
      deis_jailbroken: "การละเมิดลิขสิทธิ์ซอฟต์แวร์",
      deis_liquid_damage: "เป็นคราบน้ำ",
      deis_non_genuine_missing_parts: "อะไหล่ไม่ใช่ของแท้/ ชิ้นส่วนหายไป",
      deis_none_above: "ไม่มีปัญหาด้านบน",
      dented: "บิ่น",
      device_condition: "สภาพตัวเครื่องด้านหลังและด้านข้างเป็นอย่างไร?",
      device_functions: "ฟังก์ชันต่างๆในเครื่องทำงานปกติหรือไม่ (ลำโพง, ไมโครโฟน, ปุ่มกดม Wi-Fi, บูลทูธ)?",
      device_locks: "เครื่องติดล็อคหรือไม่? (ล็อคหน้าจอ, Find My, Apple ID/บัญชี Google , การจัดการล็อคทางไกล)",
      fingerprint_faceid: "ระบบสแกนลายนิ้วมือ / สแกนใบหน้าทำงานหรือไม่?",
      flawless: "ไม่มีตำหนิ",
      heavily_scratched: "รอยลึก",
      lcd_screen: "สภาพจอ LCD / หน้าจอ เป็นอย่างไร?",
      minor_scratches: "2-3 รอยเล็กๆ",
      no: "ไม่",
      not_working_conditional: "จอไม่ทำงาน(LCD เป็นเส้น, จอเบิร์น, เป็นเงา, มีจุดสีที่จอ, ระบบสัมผัสที่จอไม่ทำงาน)  ",
      phone_locks: "อุปกรณ์ของคุณมีปัญหาดังต่อไปนี้หรือไม่?",
      yes: "ใช่"
    };
    return translations[questionCode] || questionText;
  };
  return <Spin spinning={isLoading} data-sentry-element="Spin" data-sentry-component="ProductDiagnosticForm" data-sentry-source-file="ProductDiagnosticForm.tsx">
      <Form autoComplete="off" form={form} initialValues={formValues.productDiagnostic} labelWrap layout="vertical" onFinish={handleOnSubmit} requiredMark="optional" scrollToFirstError data-sentry-element="Form" data-sentry-source-file="ProductDiagnosticForm.tsx">
        <div className="mb-4 space-y-4">
          <QuestionTitle description={t("tradeIn.stepTwo.questions", {
          count: questions?.data?.diagnosticChecklists?.length ?? 0
        })} title={t("tradeIn.stepTwo.title")} data-sentry-element="QuestionTitle" data-sentry-source-file="ProductDiagnosticForm.tsx" />
          <Stepper count={3} step={step} data-sentry-element="Stepper" data-sentry-source-file="ProductDiagnosticForm.tsx" />
        </div>
        <TradeInResult data-sentry-element="TradeInResult" data-sentry-source-file="ProductDiagnosticForm.tsx" />
        {questions?.data?.diagnosticChecklists.map((question, index) => {
        if (question.diagnosticOptionGroup.diagnosticOptionGroupType === "single" && question.diagnosticOptionGroup.diagnosticOptionGroupCode === "yes_no") {
          return <Form.Item className="h-auto" key={question.diagnosticChecklistId} label={`${index + 1}. ${translateText(question.diagnosticFunctionalityCode, question.diagnosticFunctionalityName)}`} name={`${question.diagnosticChecklistCode}`} rules={[{
            message: t("tradeIn.validate.required"),
            required: true
          }]}>
                <Radio.Group optionType="button">
                  {question.diagnosticOptionGroup.diagnosticOptions.map(option => <Radio key={option.diagnosticOptionCode} value={option.diagnosticOptionCode}>
                        {translateText(option.diagnosticOptionCode, option.diagnosticOptionName)}
                      </Radio>)}
                </Radio.Group>
              </Form.Item>;
        }
        if (question.diagnosticOptionGroup.diagnosticOptionGroupType === "multiple") {
          return <Form.Item className="h-auto" key={question.diagnosticChecklistId} label={`${index + 1}. ${translateText(question.diagnosticFunctionalityCode, question.diagnosticFunctionalityName)}`} name={`${question.diagnosticChecklistCode}`} rules={[{
            message: t("tradeIn.validate.required"),
            required: true
          }]}>
                <Checkbox.Group className="grid gap-1" options={question.diagnosticOptionGroup.diagnosticOptions.map(option => ({
              label: translateText(option.diagnosticOptionCode, option.diagnosticOptionName),
              value: option.diagnosticOptionCode
            }))} />
              </Form.Item>;
        }
        return <Form.Item className="h-auto" key={question.diagnosticChecklistId} label={`${index + 1}. ${translateText(question.diagnosticFunctionalityCode, question.diagnosticFunctionalityName)}`} name={`${question.diagnosticChecklistCode}`} rules={[{
          message: t("tradeIn.validate.required"),
          required: true
        }]}>
              <Select className="w-full" options={question.diagnosticOptionGroup.diagnosticOptions.map(option => ({
            label: translateText(option.diagnosticOptionCode, option.diagnosticOptionName),
            value: option.diagnosticOptionCode
          }))} placeholder={t("tradeIn.stepTwo.select.label")} />
            </Form.Item>;
      })}
        <div className="flex justify-between gap-2">
          <Button className="w-full" htmlType="button" onClick={() => setStep(1)} data-sentry-element="Button" data-sentry-source-file="ProductDiagnosticForm.tsx">
            {t("tradeIn.previous")}
          </Button>
          <Button className="w-full" htmlType="submit" type="primary" data-sentry-element="Button" data-sentry-source-file="ProductDiagnosticForm.tsx">
            {t("tradeIn.next")}
          </Button>
        </div>
      </Form>
    </Spin>;
}
export default ProductDiagnosticForm;