"use client";

import { useConfigurationStore } from "@/lib/configuration/hooks/useConfigurationStore";
import { formatDate } from "@/utils/format";
import { useTranslation } from "next-i18next";
export interface CampaignStartDateProps {
  endDate: null | string | undefined;
  startDate: null | string | undefined;
}
export function CampaignStartDate() {
  const {
    i18n,
    t
  } = useTranslation();
  const {
    campaignStartDate,
    isCampaignActive
  } = useConfigurationStore();
  let text = t("general.campaignActive");
  if (!isCampaignActive && campaignStartDate) {
    text = t("general.campaignStart") + " " + formatDate(campaignStartDate as unknown as string, i18n.language === "th" ? "th-TH" : "en-US");
  }
  return <p className="mb-10 mt-16 text-center text-gray-600" data-sentry-component="CampaignStartDate" data-sentry-source-file="CampaignStartDate.tsx">{text}</p>;
}